import React from 'react';
import CommentBox from '../components/comment-box';

export default class BlogPage extends React.Component {
  render() {
    return (
      <div>
        <div className="cell">
          <article className="article">
            <h1 className="article-title">Tampa Bay Tibi</h1>
            <div className="article-body">
              <p>
                We are a group of passionate, like-minded people excited about making
                a great organic beverage product for the Tampa Bay Community. We are
                Asim Stevens, Michelle Burgess, Brianna Collins and Todd Whitesell.
              </p>
              <blockquote>
                Mission Statement: Tampa Bay Tibi Company strives to be the first locally available
                healthy beverage alternative with broad public appeal.
              </blockquote>
              <p>
                Tampa Bay Tibi Company was first created at Startup Weekend Tampa: Beverage Edition.
                we want to thank our mentors Daniel McDonald, Daniel Greco, Corey Gilmore,
                Christopher McElveen, Leon McIntosh, Jessica Wolfe, Chelsea Palmar, Michael Norton
                and everyone who participated in #SWTampa.
                A special thanks to the Hillsborough ECC for hosting the @startuptampa events.
              </p>
            </div>
          </article>
        </div>

        <CommentBox apiUrl="api/blog/comments.json" />
      </div>
    )
  }
}
